<template>
  <span>
    ✨ 并行网关无设置项
  </span>
</template>

<script>
export default {
  name: "ConcurrentNodeConfig",
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
span{
    color: #919398;
}
</style>
